<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 618px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">掲示板<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{name: 'Dashboard'}"> ダッシュボード </router-link>
            </li>

            <li><span>掲示板</span></li>
          </ul>
        </nav>
      </div>

      <div v-if="messagecolumn['admin_create']" class="command_wrap">
        <div class="leftarea btn_wrap">
          <div class="btn_norm">
            <router-link :to="{name: 'Admin messagecreate',params: { type:message_type }}">
              {{messagecolumn['admin_create']}}
            </router-link>
          </div>
        </div>
        <!--leftarea-->
        <div class="rightarea"></div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->

      <div class="contentout_wrap">
        <div class="content_wrap">
          <div class="box_wrap">
            <div class="box_header">
              <h2 class="ttl_2 left m_r20">掲示板一覧</h2>
              <div class="form_outwrap left">
                <div class="wrap wid_200px m_b0">
                  <div class="in_wrap">
                    <div class="select_wrap min">
                      <select
                        @change="StatusChange($event)"
                        name="list_type_select"
                      >
                        <option value="all">すべて</option>
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                        <option v-if="messagecolumn['status_list'] == '承認'" value="申請中">申請中</option>
                        <option v-if="messagecolumn['status_list'] == '承認'" value="承認済">承認済</option>
                        <option v-if="messagecolumn['status_list'] == '承認'" value="却下">却下</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tool_wrap">
              <div class="bulktool">
                <div class="m_r50">
                  <span class="selectednum"></span><span>が選択済みです</span>
                </div>

                <div class="delete commandtext js-modal-open">
                  <button value="アーカイブ" class="" dusk="delete_data">
                    アーカイブ
                  </button>
                </div>
              </div>
              <div id="drawer_bg" class="show"></div>
              <div class="command_wrap_inbox">
                <div class="leftarea">
                  <div class="search_button_wrap" id="dsplanonepc">
                    <button class="button_search" @click="getSearchData()">
                      検索<i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div class="wrap m_b10">
                    <div class="seach_contents">
                      <div class="search_box reverse sp_none">
                        <div class="search_wrap wid_100per">
                          <div class="input_box">
                            <label
                              ><!--title--><input
                                type="text"
                                name="titlebody"
                                class="form_dsn"
                                id="searchInput"
                                autocomplete="off"
                                placeholder="タイトル 本文"
                                v-model="serachData.typeContent"
                            /></label>
                          </div>
                          <div class="megamenu b_350" style="display: none">
                            <div class="in_wrap">
                              <p
                                id="megamenu_btn"
                                class="remove_btn tooltip tip-top"
                              >
                                <i class="fas fa-times"></i>
                              </p>
                              <div class="flex_wrap">
                                <table class="formtable">
                                  <tbody>
                                    <tr>
                                      <th class="wid_20per v_m">ユーザー</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="update_user_id"
                                                  v-model="serachData.user"
                                                >
                                                  <option value="未選択">
                                                    未選択
                                                  </option>
                                                <option v-for="userlist in this.userlists.slice()"  v-bind:value="userlist.id" :key="userlist.id">{{ userlist.last_name }}{{ userlist.first_name }}</option>

                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="wid_20per v_m">ステータス</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="status"
                                                  v-model="serachData.status"
                                                >
                                                  <option value="all">未選択</option>
                                                  <option value="公開">公開</option>
                                                  <option value="下書き">下書き</option>
                                                  <option v-if="messagecolumn['status_list'] == '承認'" value="申請中">申請中</option>
                                                  <option v-if="messagecolumn['status_list'] == '承認'" value="承認済">承認済</option>
                                                  <option v-if="messagecolumn['status_list'] == '承認'" value="却下">却下</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="wid_20per v_m">公開日</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0 time_wrap">
                                            <div class="in_wrap flex_wrap f_sp">
                                              <div
                                                class="
                                                  wrap
                                                  wid_49per
                                                  m_b0
                                                  time_wrap
                                                "
                                              >
                                                <label class="ttl_3"
                                                  >開始日

                                                  <input
                                                    name="public_start"
                                                    type="datetime-local"
                                                    placeholder="YYYY/MM/DD "
                                                    class="
                                                      input-date
                                                      publish_start
                                                    "
                                                    autocomplete="off"
                                                    v-model="serachData.published_start"
                                                  />
                                                </label>
                                              </div>
                                              <div
                                                class="wrap wid_50per m_b0 p_r0 time_wrap"
                                              >
                                                <label class="ttl_3"
                                                  >終了日

                                                  <input
                                                    name="public_end"
                                                    type="datetime-local"
                                                    placeholder="YYYY/MM/DD "
                                                    class="
                                                      input-date
                                                      publish_end
                                                    "
                                                    autocomplete="off"
                                                    v-model="serachData.published_end"
                                                  />
                                                </label>
                                              </div>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="publishedStartValidated"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .start_date_format
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="publishedEndValidated"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .end_date_format
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>

                                            <div
                                              class="msg_wrap"
                                              v-if="startGreatThen"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .start_date_limit
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="endLessThen"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .end_date_limit
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="flex_wrap f_e">
                                <div class="btn_min">
                                  <button @click="inputSearch()">検索</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="search_button">
                          <button @click="inputSearch()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="in_wrap">
                        <div class="flex_wrap">
                          <ul class="count_list">
                            <!-- <li v-if="pagination.total > 0">
                              <span>検索結果：{{ pagination.total }} </span>
                            </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--wrap-->
                </div>
                <!--leftarea-->
                <div class="rightarea">
					<ul v-if="total > get_post_num"   class="pagination" role="navigation">
                  <li
                    class="page-item"
                    style="cursor: pointer"
                    v-bind:class="[
                      pagination.current_page == 1 ? 'disabled' : '',
                    ]"
                    @click="setPage(pagination.current_page - 1)"
                    rel="prev"
                    aria-label="« 前へ"
                  >
                    <span class="page-link" aria-hidden="true">‹</span>
                  </li>
                  <li
                    style="cursor: pointer"
                    class="page-item"
                    aria-current="page"
                    v-for="n in pagination.last_page"
                    :key="n.index"
                    v-bind:class="[
                      pagination.current_page == n ? 'active' : '',
                    ]"
                    @click="setPage(n)"
                  >
                    <span class="page-link">{{ n }}</span>
                  </li>
                  <li
                    style="cursor: pointer"
                    class="page-item"
                    aria-label="次へ »"
                    @click="setPage(pagination.current_page + 1)"
                    v-bind:class="[
                      pagination.last_page == pagination.current_page
                        ? 'disabled'
                        : '',
                    ]"
                  >
                    <span class="page-link" aria-hidden="true">›</span>
                  </li>
                </ul>
                </div>
                <!--rightarea-->
              </div>
              <!--command_wrap-->
            </div>
            <!--tool_wrap-->
            <form id="">
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="tbl">
                    <tbody>
                      <tr>
                        <th class="chkarea">
                          <label class="checkbox_wrap" dusk="checkall">
                            <input
                              type="checkbox"
                              name=""
                              value=""
                              class="chkbox allchk allcheckbox"
                            /><span></span>
                          </label>
                        </th>
                        <th class="t_l">タイトル</th>
                        <th class="wid_10per" v-if="messagecolumn['target_ids']">{{messagecolumn['target_ids']}}</th>
                        <th class="wid_10per">作成者</th>
                        <th class="dtheader wid_15per">投稿日</th>
                        <th class="command wid_10per">公開状況</th>
                        <th class="wid_15per"></th>
                      </tr>

                      <!-- <tr class="nopublic"> -->
                      <tr
                        v-for="message in messages.slice()"
                        :key="message.id"
                        v-bind:class="[message.status == '下書き' ? 'nopublic' : '',]"
                      >
                        <td class="t_c v_m" style="width: 50px;">
                          <label class="checkbox_wrap" dusk="テスト入力">
                            <input
                              type="checkbox"
                              name="status_chg[]"
                              v-bind:value="message.id"
                              class="chkbox"
                              @change="delete_changed(message.id)"
                            />
                            <span></span>
                          </label>
                        </td>

                        <td class="v_m p_t15 p_b15">
                          <router-link :to="{ name: 'Admin board show',params: { id: message.id }}">{{ message.title }}</router-link>
                        </td>

                        <td class="t_c v_m">
                          <!-- <img
                            v-if="message?.loading"
                            src="@/assets/images/spinner/spinner.gif"
                            width="25"
                            height="25"
                            style="margin-right: 10px"
                          /> -->
                          <span v-if="message?.from_user">
                            {{ message.from_user.last_name }} {{ message.from_user.first_name }}
                          </span>
                          <span v-else>--</span>
                        </td>

                        <td class="date t_r">
                          <time class="en" :datetime="formatDate_time(message.created_at)">{{ formatDate(message.created_at) }}</time>
                        </td>

                        <td class="t_c v_m">
                          <span>{{ message.status }}</span>
                        </td>

                        <td class="t_c v_m command_area">
                          <div class="commandlist">
                            <div class="txt">
                              <router-link
                                :to="{
                                  name: 'Admin board show',
                              params: { id: message.id },
                                }"
                              >
                                表示</router-link
                              >
                            </div>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <!-- <p v-else>検索結果はありません</p> -->

                  <!--tbl-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </form>

            <div class="command_wrap_inbox m_t10 m_b30">
              <div class="rightarea">
                <!-- <Pagination v-if="paginationShow" /> -->
              </div>
              <!--rightarea-->
            </div>
            <!--command_wrap-->
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>

  <div
    class="modal"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">確認</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>アーカイブに移動しますか？</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscard()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deleteConfirm()"
                      class="btn btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscard()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->
</template>

<script>
import "@/assets/front_component/js/functions.js";
//import "@/assets/front_component/js/scroll-hint.min.js";
import message from "@/apis/Message";
import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import dayjs from 'dayjs';
import user from "@/apis/User";
import ScrollHint from 'scroll-hint';
import striptags from 'striptags';

export default {
  name: "message",
  data() {
    return {
      serachData: {
      user: "未選択",
      type: "",
      status: "all",
      published_start: null,
      published_end: null,
    },
    userlists:[],
    messages: [],
    cretemessage: {target_ids:[]},
    nameError: "",
    emailError: "",
    loader: false,
    showmessage: {},
    editmessage: {},
    MessageColumn() {
      return MessageColumn
    },
    messagecolumn: {},
    MessageType() {
      return MessageType
    },
    messagetype: {},
    now_post_num: 25,
    get_post_num: 25,
    total:'',
    order: 'desc',
    status: 'all',
    pagination:[],
    setDeleteId:[],
    delete_id:[],

    publishedStartValidated: false,
    publishedEndValidated: false,
    startGreatThen: false,
    endLessThen: false,
  };
},
  components: {},
  beforeCreate(){
    $(function(){
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $('.g_header_list').removeClass('open');
      $('#button').removeClass('active');
      $(document.body) .removeClass('open');
      var result = $('body').attr('style');
      var int_data = result.replace(/[^0-9]/g, '');
      $("html,body").css({
        "overflow": "auto",
        "height": "auto",
      });
      //bodyfixedを解除する
      $('body').css({
        'position': '',
        'width': '',
        'top': ''
      });
      $(window).scrollTop(int_data); 
      $('.g_header_list  > li.has_child').removeClass('open');
      $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });
  },
created() {
console.log( this.$route.params.type );
this.message_type = 'board';
this.messagetype = MessageType[this.message_type];
this.messagecolumn = MessageColumn[this.message_type];
this.serachData.type = this.message_type;
if(this.messagecolumn == null){
this.$router.push({ name: 'Front 404' });
}
this.getUserLists();
this.messageList(this.message_type,this.get_post_num,this.order,this.status);
},
methods: {
delete_changed(id){
this.delete_id.push(id);
console.log(this.delete_id);
},
deleteConfirm(){
this.delete_id.forEach(id => {
this.deletemessage(id);
});
$("#confirmModal").removeClass("open");
$(".bulktool").removeClass("open");
$(".command_wrap_inbox").removeClass("hide");
},
getUserLists() {
user.list()
.then((response) => {
if (response != null) {
this.userlists = response.data.data;
console.log(response.data.data);
}
})
.catch((error) => {
console.log(error);
this.$router.push({ name: 'Front Error Catch' });
})
.finally(() => {
this.loader = false;
});
},
inputSearch(){
console.log(this.serachData);
var query = 'type=' + this.serachData.type + '&' + this.order + '=created_at&per=' + this.get_post_num;
console.log(query);
if(this.serachData.user != null && this.serachData.user !='未選択'){
query += '&from_id=' + this.serachData.user;
}
if(this.serachData.status != null && this.serachData.status != 'all'){
query += '&status=' + this.serachData.status;
}
if(this.serachData.published_start != null){
query += '&datetime=published_at@' + this.serachData.published_start;
}
if(this.serachData.published_end != null){
query += '&datetime=published_at@' + this.serachData.published_end;
}

if(this.serachData.typeContent != null){
query += '&keyword=title,content@' + this.serachData.typeContent;
}
this.messageListQuery(query);
},
setPage(page) {
console.log(page);
console.log(this.pagination);
var query = this.pagination.links[page].url;
var query_path = query.substr(query.indexOf('?') + 1);
console.log(query_path);
this.messageListQuery(query_path);
},
formatDate: dateStr => dayjs(dateStr).format('YYYY.MM.DD'),
formatDate_time: dateStr => dayjs(dateStr).format('YYYY-MM-DD'),
deletemessage(messageid) {
message.delete(messageid)
.then((response) => {
if (response != null) {
console.log(response);
this.messageList(this.message_type,this.get_post_num,this.order,this.status);
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
this.loader = false;
});
},
messageList(type,num,order,status) {
message.listNumAdmin(type,num,order,status)
.then((response) => {
if (response != null) {
this.messages = response.data.data;
this.pagination = response.data;
this.total = response.data.total;
this.messages.forEach(message_single => {
message_single.content = striptags(message_single.content);
if(message_single.target_ids != null){
message_single.target_user = [];
message_single.target_ids.forEach(user_id => {
user.show(user_id)
.then((response) => {
if (response != null) {
message_single.target_user.push(response.data.data);
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
});
});
}
if(message_single.from_id != null){
message_single.from_user = [];
user.show(message_single.from_id)
.then((response) => {
if (response != null) {
message_single.from_user = response.data.data;
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
});
}
});
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
this.loader = false;
});
},
messageListQuery(query) {
message.listQuery(query)
.then((response) => {
if (response != null) {
this.messages = response.data.data;
this.pagination = response.data;
this.messages.forEach(message => {
message.content = striptags(message.content);
if(message.target_ids != null){
message.target_user = [];
message.target_ids.forEach(user_id => {
user.show(user_id)
.then((response) => {
if (response != null) {
message.target_user.push(response.data.data);
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
this.loader = false;
});
});
if(message.from_id != null){
message.from_user = [];
user.show(message.from_id)
.then((response) => {
if (response != null) {
message.from_user = response.data.data;
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
});
}
}
});
console.log(response.data);
}
})
.catch((error) => {
console.log(error);
})
.finally(() => {
this.loader = false;
});
},
onOrderChange(event_order) {
this.order = event_order.target.value;
this.messageList(this.message_type,this.get_post_num,this.order,this.status);
},
StatusChange(event_status) {
console.log(event_status.target.value);
this.status = event_status.target.value;
this.messageList(this.message_type,this.get_post_num,this.order,this.status);
},
closeDiscard(){
$("#confirmModal").removeClass("open");
}
},
mounted() {
var vueThis = this;
$(document).ready(function () {
var hsize = $(window).height();
$(".maincontent").css("min-height", hsize - 60 + "px");
});
$(function () {
$(".js-modal-open").on("click", function () {
var cntall = $(".allchk:checked").length;
var cnt = $(".tbl input:checkbox:checked").length;
this.setDeleteId = $(".tbl input:checkbox:checked").val();


var checkLength = eval(cnt - cntall);


if (checkLength >= "1") {
$(".modal").fadeIn();
$("#confirmModal").addClass("open");
}
return false;
});
});
$(function () {
$(document).on("click", ".allcheckbox", function () {
$(this)
.closest("table")
.find("tbody :checkbox")
.prop("checked", this.checked)
.closest("tr")
.toggleClass("selected", this.checked);
});
$("tbody :checkbox").on("click", function () {
$(this).closest("tr").toggleClass("selected", this.checked);
$(this)
.closest("table")
.find(".allchk")
.prop(
"checked",
$(this).closest("table").find("tbody :checkbox:checked").length ==
$(this).closest("table").find("tbody :checkbox").length
);
});
});

$(function () {
$(document)
.on("click", "input:checkbox", function () {
vueThis.deleteId = $(this).val();

var cntall = $(".allchk:checked").length;
var cnt = $(".tbl input:checkbox:checked").length;

// if()
$(".selectednum").text(cnt - cntall + "件");
if (cnt == 0) {
$(".bulktool").removeClass("open");
$(".command_wrap_inbox").removeClass("hide");
} else {
$(".bulktool").addClass("open");
$(".command_wrap_inbox").addClass("hide");
}
})
.trigger("change");
});
document.body.className = 'page_control';
this.$nextTick(function() {
$(function(){
$('.menu_icon').click(function(){
if($('.menu_list_wrap').hasClass('show')){
$('.menu_list_wrap').removeClass('show');
}else{
$('.menu_list_wrap').addClass('show');
}
});
});
$(document).click(function(event) {
if(!$(event.target).closest('.menu_wrap').length) {
$('.menu_list_wrap').removeClass('show');
}
});
$(function(){
var content_height = $("#app").height(); // コンテンツの高さを取得
var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
$(window).on("scroll", function () {
var scroll = $(window).scrollTop();
var windowHeight = $(window).height();
//ページトップ表示
if(scroll > pagetop_show && scroll>60){
$('.pagetop_wrap').addClass('show');
} else {
$('.pagetop_wrap').removeClass('show');
}
});
});
$(function(){
$('#js-loader').delay(300).fadeOut(600);
$(window).scrollTop(0);
});
new ScrollHint('.js-scrollable', {
suggestiveShadow: true,
i18n: {
scrollable: 'スクロールできます'
}
});
});
$(function () {
vueThis.publishedStartValidated = false;
vueThis.publishedEndValidated = false;
// ボックスを表示する
$(".form_dsn").on("click", function (e) {
$(".megamenu").fadeIn("fast");
});

// ボックス内をクリックした際ボックスを非表示にしない
$(".megamenu").on("click", function (event) {
event.stopPropagation();
});

// ボックス外をクリックした際ボックスを非表示にする
// ボックス外をクリックした際ボックスを非表示にする
$(document).on("click", function (e) {

if (
e.target.className !== "megamenu" &&
e.target.className !== "form_dsn"
) {
if (
e.target.className ==
"ui-datepicker-prev ui-corner-all ui-state-hover ui-datepicker-prev-hover" ||
e.target.className == "ui-icon ui-icon-circle-triangle-w"
) {
return false;
}
if (
e.target.className ==
"ui-datepicker-next ui-corner-all ui-state-hover ui-datepicker-next-hover" ||
e.target.className == "ui-icon ui-icon-circle-triangle-e"
) {
return false;
}

$(".megamenu").fadeOut("fast");
}
});
});
},
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
