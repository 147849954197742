<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>APPROVAL</span></div>
    <div class="content_wrap">
      <h2>承認</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li><span>承認一覧</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="content_in_wrap">
            <div class="innar">
              <div class="js-scrollable">
                <table class="tbl_list">
                  <tr class="sort">
                    <th class="tbl_list_day t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>すべて</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="group"
                                  class="select_input"
                                  type="radio"
                                  name="groupchange"
                                  id="group_all"
                                  v-on:change="onGroupChange"
                                  value="all"
                                />
                                <label class="select_label" for="group_all"
                                  >すべて</label
                                >
                              </li>
                              <li class="select_option">
                                <input
                                  v-model="group"
                                  class="select_input"
                                  type="radio"
                                  name="groupchange"
                                  id="group_idea"
                                  v-on:change="onGroupChange"
                                  value="idea"
                                />
                                <label class="select_label" for="group_idea"
                                  >創発</label
                                >
                              </li>
                              <li class="select_option">
                                <input
                                  v-model="group"
                                  class="select_input"
                                  type="radio"
                                  name="groupchange"
                                  id="group_sdgs"
                                  v-on:change="onGroupChange"
                                  value="sdgs"
                                />
                                <label class="select_label" for="group_sdgs"
                                  >SDGs個人目標</label
                                >
                              </li>
                              <li class="select_option">
                                <input
                                  v-model="group"
                                  class="select_input"
                                  type="radio"
                                  name="groupchange"
                                  id="group_challenge"
                                  v-on:change="onGroupChange"
                                  value="challenge"
                                />
                                <label
                                  class="select_label"
                                  for="group_challenge"
                                  >チャレンジシート</label
                                >
                              </li>
                              <li class="select_option">
                                <input
                                  v-model="group"
                                  class="select_input"
                                  type="radio"
                                  name="groupchange"
                                  id="group_photolog"
                                  v-on:change="onGroupChange"
                                  value="photolog"
                                />
                                <label
                                  class="select_label"
                                  for="group_photolog"
                                  >フォトログ</label
                                >
                              </li>
                              <li class="select_option">
                                <input
                                  v-model="group"
                                  class="select_input"
                                  type="radio"
                                  name="groupchange"
                                  id="group_column"
                                  v-on:change="onGroupChange"
                                  value="column"
                                />
                                <label
                                  class="select_label"
                                  for="group_column"
                                  >コラム</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_ttl">
                      <div class="sort_ttl"><span>タイトル</span></div>
                    </th>
                    <th class="tbl_list_day t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>投稿日</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="order"
                                  class="select_input"
                                  type="radio"
                                  name="orderchange"
                                  id="order_desc"
                                  v-on:change="onOrderChange"
                                  value="desc"
                                />
                                <label class="select_label" for="order_desc"
                                  >新しい順</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="order"
                                  class="select_input"
                                  type="radio"
                                  name="orderchange"
                                  id="order_asc"
                                  v-on:change="onOrderChange"
                                  value="asc"
                                />
                                <label class="select_label" for="order_asc"
                                  >古い順</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_name t_c">
                      <div class="sort_ttl"><span>投稿者</span></div>
                    </th>
                    <th class="tbl_list_sta t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>ステータス</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_shinsei"
                                  v-on:change="onStatusChange"
                                  value="申請中"
                                />
                                <label class="select_label" for="status_shinsei"
                                  >申請中</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_kyakka"
                                  v-on:change="onStatusChange"
                                  value="却下"
                                />
                                <label class="select_label" for="status_kyakka"
                                  >却下</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_syounin"
                                  v-on:change="onStatusChange"
                                  value="承認済"
                                />
                                <label class="select_label" for="status_syounin"
                                  >承認済</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_command">
                      <div class="sort_ttl"><span>操作</span></div>
                    </th>
                  </tr>
                  <tr v-for="message in messages.slice()" :key="message.id">
                    <td class="tbl_list_ttl" v-if="message.type == 'idea'">
                      創発
                    </td>
                    <td class="tbl_list_ttl" v-else-if="message.type == 'sdgs'">
                      SDGs個人目標
                    </td>
                    <td
                      class="tbl_list_ttl"
                      v-else-if="message.type == 'challenge'"
                    >
                      チャレンジシート
                    </td>
                    <td class="tbl_list_ttl" v-else-if="message.type == 'news'">
                      お知らせ
                    </td>
                    <td
                      class="tbl_list_ttl"
                      v-else-if="message.type == 'column'"
                    >
                      コラム
                    </td>
                    <td
                      class="tbl_list_ttl"
                      v-else-if="message.type == 'photolog'"
                    >
                      フォトログ
                    </td>
                    <td class="tbl_list_ttl">
                      <router-link
                        v-if="
                          message.type == 'challenge' || message.type == 'sdgs'
                        "
                        :to="{
                          name: 'Control messageshow',
                          params: { type: message.type, id: message.id },
                        }"
                      >
                        {{ showYear(message.option) }}
                      </router-link>
                      <router-link
                        v-else
                        :to="{
                          name: 'Control messageshow',
                          params: { type: message.type, id: message.id },
                        }"
                        >{{ message.content }}</router-link
                      >
                    </td>
                    <td class="tbl_list_day t_c">
                      <span class="day_time">
                        <img
                          src="@/assets/front_component/images/assets/board01.png"
                          alt="時間"
                        /><time
                          class="en"
                          :datetime="formatDate_time(message.created_at)"
                          >{{ formatDate(message.created_at) }}</time
                        >
                      </span>
                    </td>
                    <td class="tbl_list_name t_c">
                      <p v-if="message.from_user">
                        {{ message.from_user.last_name
                        }}{{ message.from_user.first_name }} 
                      </p>
                       <span v-else>--</span>
                    </td>
                    <td class="tbl_list_sta t_c">{{ message.status }}</td>
                    <td class="tbl_list_command">
                      <div class="commandlist t_c">
                        <div class="command">
                          <a
                            v-if="message.status == '承認済'"
                            class="edit active"
                            data-tip="承認"
                            >承認</a
                          >
                          <!--
                          <a
                            v-else-if="message.status == '却下'"
                            class="edit not_active"
                            data-tip="承認"
                            >承認</a
                          >
                      -->
                    </div>
                    <div class="command p_relative cmd-flex">
                      <a
                          v-if="message.status !== '却下' && message.status != '承認済'"
                          class="edit approve_btn"
                          :class="active"
                          @mouseover="approval_ok_message(message.id, 'approve')"
                          data-tip="承認"
                          >承認</a
                        >
                          <a
                            v-if="message.status == '却下'"
                            class="delete active"
                            data-tip="却下"
                            >却下</a
                          >
                          <!--
                          <a
                            v-else-if="message.status == '承認済'"
                            class="edit active"
                            data-tip="却下"
                            >却下</a
                          >
                      -->          
                          <a
                            v-else-if="message.status !== '承認済'"
                            class="delete m_l"
                            @mouseover="approval_ng_message(message.id, 'reject')"
                            data-tip="却下"
                            >却下</a
                          >
                          <div class="submit_wrap click_pop">
                            <Form
                              id="aprroval_ng_modal"
                              style="display: none"
                              enctype="multipart/form-data"
                              autocomplete="off"
                              @submit="approval_ng_message_post"
                              class="click_popup"
                            >
                              <div class="innar">
                                <div class="form_outwrap">
                                  <div class="wrap wid_100per" :id="btnStatus === 'reject' ? 'arrow' : ''">
                                    <p class="ttl_03">
                                      {{btnStatus === 'approve' ? 'コメント' : '理由'}}
                                      <ErrorMessage
                                        class="each_msg"
                                        name="content"
                                      />
                                    </p>
                                    <div class="in_wrap input_wrap">
                                      <textarea
                                        name="content"
                                        cols="30"
                                        rows="5"
                                        id="approval_input"
                                        v-model="approval_ng.content"
                                        placeholder="本文を入力してください"
                                      >
                                      </textarea>
                                      <Field
                                        name="content"
                                        type="hidden"
                                        class="form-control"
                                        placeholder="本文を入力してください"
                                        v-model="approval_ng.content"
                                        id="approval_input"
                                        :rules="btnStatus === 'reject' ? isRequired : ''"
                                      />
                                      <div class="t_c">
                                        <button
                                          type="submit"
                                          class="btn btn_submit btn_submit-blue"
                                        >
                                          送信
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div id="load" style="display: none">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
                <div
                  id="more_btn"
                  class="t_c"
                  v-if="get_post_num < total && now_post_num < total"
                >
                  <div class="btn_wide">
                    <button v-on:click="messageListmore">さらに読み込む</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "@/assets/front_component/js/functions.js";
//import "@/assets/front_component/js/scroll-hint.min.js";
import message from "@/apis/Message";
import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import dayjs from "dayjs";
import user from "@/apis/User";
import ScrollHint from "scroll-hint";
import striptags from "striptags";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "message",
  data() {
    return {
      btnStatus: "",
      messages: [],
      cretemessage: { target_ids: [] },
      nameError: "",
      emailError: "",
      loader: false,
      showmessage: {},
      editmessage: {},
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      now_post_num: 25,
      get_post_num: 25,
      total: "",
      order: "desc",
      status: "all",
      group: "all",
      approval_ng: {},
      more_load: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $("#load").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.messagetype = "challenge,idea,sdgs,news,column,photolog";
    this.messagecolumn = MessageColumn[this.message_type];
    this.messageList(
      "challenge,idea,sdgs,news,column,photolog",
      this.get_post_num,
      this.$User.id,
      this.order,
      this.status
    );
  },
  methods: {
    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
      }
    },
    approval_ok_message(messageid, msg) {
      this.btnStatus = msg;
      this.approval_ng.id = messageid;
      $(".click_popup").css("display", "none");
      this.approval_ng.content = "";
      $(".approve_btn").each(function () {
        $(this).click(function (e) {
          console.log($(this).next().next().next());
          $(this).next().next().children(".click_popup").css("display", "block");
        });
      });
    },
    approval_ng_message(messageid, msg) {
      this.btnStatus = msg;
      this.approval_ng.id = messageid;
      $(".click_popup").css("display", "none");
      this.approval_ng.content = "";
      $(".delete").each(function () {
        $(this).click(function (e) {
          $(this).next().children(".click_popup").css("display", "block");
        });
      });
    },
    approval_ng_message_post() {
      // $("#aprroval_ng_modal").hide();
      message
        .show(this.approval_ng.id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;
            // this.showmessage.status = "却下";
            if(this.btnStatus === 'approve'){
              this.showmessage.status = "承認済";
              var post_tag = [];
              if (this.showmessage.tags) {
                this.showmessage.tags.forEach((tag) => {
                  post_tag.push(tag.id);
                });
                this.showmessage.tags = post_tag;
              }
            }else if(this.btnStatus === 'reject'){
              this.showmessage.status = "却下";
            }

            this.showmessage.published_at = this.format_date(
              this.editmessage.published_at
            );
            this.showmessage.is_approval = true;

            if (this.showmessage.tags) {
              delete this.showmessage.tags;
            }

            message
              .update(this.showmessage, this.approval_ng.id)
              .then((response) => {
                let toObj = response.data.data;
                if (response != null) {
                  let approvalContent = '';
                  if (this.approval_ng.content === undefined || this.approval_ng.content === '') {
                    approvalContent = 'no content'
                  }else{
                    approvalContent = this.approval_ng.content
                  }
                  var ng_form = {
                    title: this.btnStatus === 'reject' ? "却下メッセージ" : "承認済メッセージ",
                    content: approvalContent,
                    content_type: "Plane",
                    parent_id: this.approval_ng.id,
                    type: "approval_message",
                    status: "公開",
                    is_approval: true
                  };

                  message
                    .register(ng_form)
                    .then((response) => {
                      if (response != null) {
                        console.log(response);
                        let fromObj = response.data.data;
                        let obj = {
                          to_user: toObj.from_id,
                          from_user: fromObj.from_id,
                          content: fromObj.content
                        }
                        message.approvalMail(obj,toObj.id).then((data)=>{
                          if (data !== null || data !== undefined) {
                            // this.$router.push({ name: "Front approval" });
                            console.log(data);
                          }
                        }).catch((error) => {
                          console.log('approval mail error',error);
                        })
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {
                      this.loader = false;
                    });

                  this.messageList(
                    "challenge,idea,sdgs,news,column,photolog",
                    this.get_post_num,
                    this.$User.id,
                    this.order,
                    this.status
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loader = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    messageList(type, num, id, order, status) {
      message
        .approval_list(type, num, id, order, status)
        .then((response) => {
          if (response != null) {

            if (response.data.data.length === 0) {
              this.table_loader = false;
            }

            this.messages = response.data.data;

            this.total = response.data.total;
            this.messages.forEach((message_single) => {
              if (
                message_single.type == "sdgs" ||
                message_single.type == "challenge"
              ) {
                if (this.IsJsonString(message_single.content)) {
                  let contentSplit = JSON.parse(message_single.content);

                  message_single.content = striptags(contentSplit.content);

                  message_single.content_1 = striptags(contentSplit.content_1);

                  message_single.content_2 = striptags(contentSplit.content_2);

                  message_single.option = contentSplit.option;
                } else {
                  message_single.option = striptags(message_single.content);
                }
              } else {
                message_single.content = striptags(message_single.content);
              }

              // if (message_single.from_id != null) {
              //   user
              //     .show(message_single.from_id)
              //     .then((response) => {
              //       if (response != null) {
              //         message_single.from_user = response.data.data;
              //       }
              //     })
              //     .catch((error) => {
              //       console.log(error);
                    
              //     })
              //     .finally(() => {
              //       $("#aprroval_ng_modal").hide();
              //       $(".click_popup").css("display", "none");
              //       this.table_loader = false;
              //     });
              // }
            });
          }
        })
        .catch((error) => {
          console.log("エラー");
          console.log(error);
        })
        .finally(() => {
          $("#aprroval_ng_modal").hide();
          $(".click_popup").css("display", "none");
          $("#load").hide();
          this.table_loader = false;
          this.loader = false;
        });
    },
    messageListmore() {
      $("#load").show();
      $("#more_btn").hide();

      this.now_post_num = this.now_post_num + this.get_post_num;
      if (this.message_type === undefined || this.message_type === null) {
        this.message_type = "challenge,idea,sdgs,news,column,photolog"
      }
      message
        .approval_list(
          this.message_type,
          this.now_post_num,
          this.$User.id,
          this.order,
          this.status
        )
        .then((response) => {
          this.more_load = true;
          if (response != null) {
            this.messages = response.data.data;

            this.messages.forEach((message) => {
              if (message.type == "sdgs" || message.type == "challenge") {
                
                if (this.IsJsonString(message.content)) {
                  let contentSplit = JSON.parse(message.content);

                  message.content = striptags(contentSplit.content);

                  message.content_1 = striptags(contentSplit.content_1);

                  message.content_2 = striptags(contentSplit.content_2);

                  message.option = contentSplit.option;
                } else {
                  message.option = striptags(message.content);
                }
              } else {
                message.content = striptags(message.content);
              }

              // if (message.from_id != null) {
              //   user
              //     .show(message.from_id)
              //     .then((response) => {
              //       if (response != null) {
              //         message.from_user = response.data.data;
              //       }
              //     })
              //     .catch((error) => {
              //       console.log(error);
              //     })
              //     .finally(() => {});
              // }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    onGroupChange(event_group) {
      if (event_group.target.value == "all") {
        this.message_type = "challenge,idea,sdgs,news,column,photolog";
        this.messageList(
          this.message_type,
          this.get_post_num,
          this.$User.id,
          this.order,
          this.status
        );
      } else {
        this.message_type = event_group.target.value;
        this.messageList(
          this.message_type,
          this.get_post_num,
          this.$User.id,
          this.order,
          this.status
        );
      }
    },
    onOrderChange(event_order) {
      this.order = event_order.target.value;
      this.messageList(
        "challenge,idea,sdgs,news,column,photolog",
        this.get_post_num,
        this.$User.id,
        this.order,
        this.status
      );
    },
    onStatusChange(event_status) {
      this.status = event_status.target.value;
      this.messageList(
        "challenge,idea,sdgs,news,column,photolog",
        this.get_post_num,
        this.$User.id,
        this.order,
        this.status
      );
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
  },
  mounted() {
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
        new ScrollHint(".js-scrollable", {
          suggestiveShadow: true,
          i18n: {
            scrollable: "スクロールできます",
          },
        });
      }
    });
  },
  updated() {
    if (this.more_load == true) {
      $("#load").hide();
      $("#more_btn").show();
      this.more_load = false;
    }
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
