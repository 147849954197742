<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>{{ messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>{{ messagetype.title_jp }}</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li>
              <span>{{ messagetype.title_jp }}一覧</span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="board_wrap">
              <ul class="news_list board" v-if="messages.length != 0">
                <li v-for="message in messages" :key="message.id">
                  <router-link
                    :to="{
                      name: 'Front event show',
                      params: { type: 'event', id: message.id },
                    }"
                  >
                    <div class="icon_wrap">
                      <img
                        :src="iconPath"
                        :alt="messagetype.title_jp"
                        class="mouse_out"
                      />
                      <img
                        :src="iconPath02"
                        :alt="messagetype.title_jp"
                        class="mouse_over"
                      />
                    </div>
                    <h3 class="txt">{{ message.title }}</h3>
                    <div class="txt_wrap" v-if="!message.from_admin">
                      <div class="mini_text">{{ message.from_group }}</div>
                    </div>
                    <div class="txt_wrap">
                      <span v-if="message?.user === null || message?.user === undefined">--</span>                      
                      <div
                        class="mini_text"
                        v-if="message.from_user && !message.from_admin"
                      >
                        <!-- {{ message.from_user.last_name}}{{ message.from_user.first_name }} --><!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                         {{ message.from_user.deleted_at ? message.from_user.last_name + message.from_user.first_name + ' (削除済み) ' :message.from_user.last_name + message.from_user.first_name }}
                      </div>
                    </div>
                    <div class="txt_wrap" v-if="message.from_admin">
                      <div class="mini_text">{{ message.from_admin }}</div>
                    </div>
                    <div class="txt_wrap">
                      <div class="board_time">
                        <img
                          src="@/assets/front_component/images/assets/board01.png"
                          alt="時間"
                        />
                        <time
                          class="en"
                          :datetime="formatDate_time(message.created_at)"
                          >{{ formatDate(message.created_at) }}</time
                        >
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
              <div v-else class="p_t50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
              <div id="load" style="display: none">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
              <div
                id="more_btn"
                class="t_c"
                v-if="5 < total && now_post_num < total"
              >
                <div class="btn_wide">
                  <button v-on:click="messageListmore">さらに読み込む</button>
                </div>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import message from "@/apis/Message";
import event from "@/apis/Event";
import dayjs from "dayjs";
import MessageType from "@/const/MessageType.json";
import $ from "jquery";
import user from "@/apis/User";
import striptags from "striptags";

import "@/assets/front_component/js/functions.js";

export default {
  name: "message",
  data() {
    return {
      iconPath: "",
      iconPath02: "",
      total: "",
      now_post_num: 25, // 表示されている数
      get_post_num: 25, // 取得する数
      messages: [],
      MessageType() {
        return MessageType;
      },
      loader: false,
      more_load: false,
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      if (from.name == "Front archive" && to.name == "Front archive") {
        $(function () {
          $("#js-loader").show();
          $(window).scrollTop(0);
        });
        this.message_type = "event";
        this.messageList(this.message_type, this.now_post_num);
        this.messagetype = MessageType[this.message_type];
        this.iconPath = require("@/assets/front_component/images/assets/" +
          this.messagetype.imgPath);
        this.iconPath02 = require("@/assets/front_component/images/assets/" +
          this.messagetype.imgPath02);
        $(function () {
          $("#js-loader").delay(500).fadeOut(600);
        });
      }
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = "event";
    this.messageList(this.now_post_num);
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath);
    this.iconPath02 = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath02);
  },
  methods: {
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    messageList(num) {
      this.loader = true;
      event
        .listNum(num)
        .then(
          (response) => {
            if (response != null) {
              this.messages = response.data.data;
              this.total = response.data.total;
              this.messages.forEach((message_single) => {
                message_single.content = striptags(message_single.content);
                if (message_single.create_user_id != null) {
                  user
                    .show(message_single.create_user_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.from_user = response.data.data;
                        console.log(message_single.from_user);
                        var group = response.data.data.group;
                        if (group != null) {
                          if (group.parent_id == null) {
                            message_single.from_group = group.name;
                          } else {
                            user
                              .get_group(group.parent_id)
                              .then((response) => {
                                if (response != null) {
                                  message_single.from_group =
                                    response.data.data.name;
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              })
                              .finally(() => {});
                          }
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
                if (message_single.target_ids != null) {
                  message_single.target_user = [];
                  message_single.target_ids.forEach((user_id) => {
                    user
                      .show(user_id)
                      .then((response) => {
                        if (response != null) {
                          message_single.target_user.push(response.data.data);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {});
                  });
                }
              });
              console.log(response.data);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageListmore() {
      $("#load").show();
      $("#more_btn").hide();

      this.now_post_num = this.now_post_num + this.get_post_num;

      event
        .listNum(this.now_post_num)
        .then(
          (response) => {
            this.more_load = true;
            if (response != null) {
              this.messages = response.data.data;
              this.messages.forEach((message_single) => {
                message_single.content = striptags(message_single.content);
                if (message_single.create_user_id != null) {
                  user
                    .show(message_single.create_user_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.from_user = response.data.data;

                        var group = response.data.data.group;
                        if (group != null) {
                          if (group.parent_id == null) {
                            message_single.from_group = group.name;
                          } else {
                            user
                              .get_group(group.parent_id)
                              .then((response) => {
                                if (response != null) {
                                  message_single.from_group =
                                    response.data.data.name;
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              })
                              .finally(() => {});
                          }
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
                if (message_single.target_ids != null) {
                  message_single.target_user = [];
                  message_single.target_ids.forEach((user_id) => {
                    user
                      .show(user_id)
                      .then((response) => {
                        if (response != null) {
                          message_single.target_user.push(response.data.data);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {});
                  });
                }
              });
              console.log(response.data);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    document.body.className = "page_board-archive";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
  },
  updated() {
    if (this.more_load == true) {
      $("#load").hide();
      $("#more_btn").show();
      this.more_load = false;
    }
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
