<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img src="@/assets/front_component/images/assets/back02.png" alt="雲" />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img src="@/assets/front_component/images/assets/back01.png" alt="雲" />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>MYPAGE</span></div>
    <div class="content_wrap">
      <h2>ユーザープロフィール</h2>
      <div class="mascot_wrap">
        <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君" />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Front mypage' }">マイページ一覧</router-link>
            </li>
            <li><span>編集</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="maypage_wrap">
              <div class="flex_maypage_wrap">
                <div class="profile_wrap">
                  <div class="profile_inwrap">
                    <div class="left_area">
                      <div
                        class="user_wrap"
                        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
                        v-if="profileImg"
                      ></div>
                      <div
                        class="user_wrap"
                        :style="{
                          backgroundImage:
                            'url(' +
                            require('@/assets/front_component/images/test/test01.png') +
                            ')',
                        }"
                        v-else
                      ></div>
                      <div class="t_c m_t10" style="display: none">
                        <div class="camera_btn">
                          <label>
                            <input
                              type="file"
                              class="input-file form-control post_img-file"
                              accept="image/*"
                              name="photolog"
                            />
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="right_area">
                      <div class="user_name">
                        <ruby
                          >{{ $User.last_name }}<rt>{{ $User.last_name_kana }}</rt>
                          {{ $User.first_name }}<rt>{{ $User.first_name_kana }}</rt></ruby
                        >
                      </div>
                      <div
                        class="director_name"
                        v-if="$User.group && $User.group.parent_name"
                      >
                        {{ $User.group.parent_name }}
                      </div>
                      <div class="director_name" v-else-if="$User.group">
                        {{ $User.group.name }}
                      </div>
                      <ul class="awards_link">
                        <li class="awards_item" v-for="num of awardTenNum" :key="num">
                          <img
                            src="@/assets/front_component/images/assets/awards01.png"
                            alt="10個"
                          />
                        </li>
                        <li class="awards_item" v-for="num of awardOneNum" :key="num">
                          <img
                            src="@/assets/front_component/images/assets/awards02.png"
                            alt="1個"
                          />
                        </li>
                      </ul>
                      <div class="reaction_wrap">
                        <div class="reaction_item">
                          <span>サンクス/ホメロン</span
                          ><a href="#target_area">{{ thanksNum }}</a>
                        </div>
                      </div>
                      <div class="g_header_item">
                        <router-link :to="{ name: 'Front approval' }"
                          class="notification_num"
                          v-if="approvalNum > 0"
                          >承認依頼：<span>&nbsp;&nbsp;&nbsp;{{ approvalNum }}</span>件</router-link
                        >
                      </div>
                      </div>
                    </div>
                    <div class="approve-reject-btn-user-profile-align">
                      <div class="btn_large">
                          <router-link :to="{ name: 'Reject' }">
                            承認/却下コメント一覧
                          </router-link>
                      </div>
                    </div>
                  </div>
                <div class="post_num_wrap">
                  <h3 class="ttl_03">今月の投稿数</h3>
                  <div class="post_area">
                    <table>
                      <tr>
                        <th>サンクス/ホメロン</th>
                        <td>
                          <div class="reaction_item">
                            <a href="#target_area" class="reaction_link">{{
                              thanksIdsCount
                            }}</a>
                            件
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>コラム</th>
                        <td>
                          <div class="reaction_item">
                            <router-link
                              :to="{
                                name: 'Control message',
                                params: { type: 'column' },
                              }"
                              class="reaction_link"
                              >{{ columnNumMouth }}</router-link
                            >
                            件
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>創発カード</th>
                        <td>
                          <div class="reaction_item">
                            <router-link
                              :to="{
                                name: 'Control message',
                                params: { type: 'idea' },
                              }"
                              class="reaction_link"
                              >{{ ideaNumMouth }}</router-link
                            >
                            件
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>気づき日報</th>
                        <td>
                          <div class="reaction_item">
                            <router-link
                              :to="{
                                name: 'Control message',
                                params: { type: 'daily' },
                              }"
                              class="reaction_link"
                              >{{ dailyNumMouth }}</router-link
                            >
                            件
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="thisterm_wrap">
                <div class="thisterm_head">
                  <h3 class="thisterm_ttl challenge">今期のチャレンジシート</h3>
                  <div class="thisterm_btn_wrap" v-if="challenge_show['content']">
                    <div class="btn_small" v-if="challenge_show_id">
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'challenge', id: challenge_show_id },
                        }"
                        >表示</router-link
                      >
                    </div>
                    <div class="btn_small" v-if="challenge_show_id">
                      <router-link
                      v-if="sdgs_show_status != '承認済'"
                        :to="{
                          name: 'Control messageedit',
                          params: { type: 'challenge', id: challenge_show_id },
                        }"
                        >編集</router-link
                      >
                      <a
                        v-else
                        class="editnot_active"
                        >編集</a
                        >
                    </div>
                  </div>
                </div>
                <div class="thisterm_list_wrap">
                  <!-- <ol class="thisterm_list" v-if="challenge_show['content']"> -->
                  <ol class="thisterm_list" v-if="challenge_show['content']">
                    <!-- <li>{{ challenge_show["content"] }}</li>
                    <li>{{ challenge_show["content_1"] }}</li>
                    <li>{{ challenge_show["content_2"] }}</li> -->
                    <li>
                      <p>{{ challenge_show["content"] }}</p>
                      <p>{{ challenge_show["content_1"] }}</p>
                      <p>{{ challenge_show["content_2"] }}</p>
                    </li>
                  </ol>
                  <p v-else>今期のチャレンジシートは投稿されていません。</p>
                </div>
              </div>
              <div class="thisterm_wrap">
                <div class="thisterm_head">
                  <h3 class="thisterm_ttl sdgs" v-if="sdgs_show['content']">
                    今期のSDGs個人目標 <span class="bold">(投稿数 : {{ sdgs_show["total_goal_count"] }}件)</span> 
                  </h3>
                  <div class="thisterm_btn_wrap">
                    <div class="btn_small" v-if="sdgs_show_id">
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'sdgs', id: sdgs_show_id },
                        }"
                        >表示</router-link
                      >
                    </div>
                    <div class="btn_small" v-if="sdgs_show_id">
                      <router-link
                        v-if="sdgs_show_status != '承認済'"
                        :to="{
                          name: 'Control messageedit',
                          params: { type: 'sdgs', id: sdgs_show_id },
                        }"
                        >編集</router-link
                      >
                      <a
                        v-else
                        class="editnot_active"
                        >編集</a
                        >
                    </div>
                  </div>
                </div>
                <div class="thisterm_list_wrap">
                  <!-- <ol class="" v-if="sdgs_show['content']"> -->
                  <ol class="thisterm_list" v-if="sdgs_show['content']">
                    <li class="border-bottom-none">
                      <h3>1.{{ sdgs_show['content'] }}</h3>
                      <p class="thisterm_list_content goal_mb">&emsp;(投稿数 : {{ sdgs_show["goal_count_1"] }}件)</p>
                      <h3>2.{{ sdgs_show['content_1'] }}</h3>
                      <p class="thisterm_list_content goal_mb">&emsp;(投稿数 : {{ sdgs_show["goal_count_2"] }}件)</p>
                      <h3>その他SDGs関連</h3>
                      <p class="thisterm_list_content goal_mb">&emsp;(投稿数 : {{ sdgs_show["goal_count_others"] }}件)</p>
                    </li>
                    <!-- <li>{{ sdgs_show["content_1"] }}</li>
                    <li>{{ sdgs_show["content_2"] }}</li> -->
                  </ol>
                  <p v-else>今期のSDGs個人目標は投稿されていません。</p>
                </div>
              </div>
            </div>

            <div class="maypage_wrap">
              <div class="post_area">
                <table>
                  <tr>
                    <th>入社日</th>
                    <td>{{ formatHireDate($User.here_date) }}</td>
                  </tr>
                  <tr>
                    <th>表彰数</th>
                    <td>
                      <div class="reaction_item">
                        <span class="reaction_link"
                          >{{ awardTenNum }}{{ awardOneNum }}</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>サンクス/ホメロン<br />(年度/通算)</th>
                    <td>
                      <div class="reaction_item">
                        <span href="#target_area" class="reaction_link">{{
                          thanksNum
                        }}</span>
                      </div>
                      /
                      <div class="reaction_item">
                        <span href="#target_area" class="reaction_link">{{
                          thanksAllNum
                        }}</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <Form
                enctype="multipart/form-data"
                autocomplete="off"
                @submit="register"
                v-if="registration_status == false"
              >
                <div class="m_b70">
                  <div class="form_outwrap">
                    <div class="wrap wid_100per m_b0">
                      <p class="ttl_03">
                        自己紹介<ErrorMessage class="each_msg" name="user_code" />
                      </p>
                      <div class="in_wrap m_b0">
                        <textarea
                          name=""
                          id=""
                          rows="7"
                          placeholder="自己紹介を入力してください。"
                          v-model="editUser.content"
                        ></textarea>
                        <Field
                          name="content"
                          type="hidden"
                          class="form-control"
                          placeholder="自己紹介を入力してください。"
                          v-model="editUser.content"
                          :rules="isRequired"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="t_r">
                    <div class="btn_norm">
                      <button type="submit" class="btn btn-primary">保存</button>
                    </div>
                  </div>
                </div>
              </Form>

              <Form
                enctype="multipart/form-data"
                autocomplete="off"
                @submit="update(registration_status)"
                v-else
              >
                <div class="m_b70">
                  <div class="form_outwrap">
                    <div class="wrap wid_100per m_b0">
                      <p class="ttl_03">
                        自己紹介<ErrorMessage class="each_msg" name="user_code" />
                      </p>
                      <div class="in_wrap m_b0">
                        <textarea
                          name=""
                          id=""
                          rows="7"
                          placeholder="自己紹介を入力してください。"
                          v-model="editUser.content"
                        ></textarea>
                        <Field
                          name="content"
                          type="hidden"
                          class="form-control"
                          placeholder="自己紹介を入力してください。"
                          v-model="editUser.content"
                          :rules="isRequired"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="t_r">
                    <div class="btn_norm">
                      <button type="submit" class="btn btn-primary">保存</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>

            <div class="board_wrap m_b100">
              <div class="ttl_select_wrap">
                <h3 class="ttl_06">投稿</h3>
                <div class="form_outwrap">
                  <div class="wrap">
                    <div class="in_wrap">
                      <div class="select_wrap">
                        <select v-on:change="onPostChange" id="departmentSelect">
                          <option value="all">すべての投稿</option>
                          <option value="news">お知らせ</option>
                          <option value="thanks">サンクス/ホメロン</option>
                          <option value="column">コラム</option>
                          <option value="idea">創発</option>
                          <option value="daily">気づき日報</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="news_list_wrap">
                <ul class="news_list board label" v-if="post_messages.length > 0">
                  <li v-for="message in post_messages" :key="message.id">
                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <div class="icon_wrap">
                        <img
                          :src="message.iconPath"
                          :alt="message.typejp"
                          class="mouse_out"
                        />
                        <img
                          :src="message.iconPath02"
                          :alt="message.typejp"
                          class="mouse_over"
                        />
                      </div>
                      <div class="cat_name" :class="message.type">
                        {{ message.typejp }}
                      </div>
                      <h3
                        v-if="message.typejp == 'サンクス' && message.target_user"
                        class="txt"
                      >
                        <!-- <img
                          v-if="message.target_user.length <= 0"
                          src="@/assets/images/spinner/spinner.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        /> -->
                        <span v-if="message.target_user.length <= 0"> -- </span>
                        <span v-else>
                          <span v-for="(target_name, key) in message.target_user" :key="key"
                            >{{ target_name.deleted_at ? target_name.last_name + ' (削除済み) ' : target_name.last_name }}さん </span
                          >へのサンクスカード 
                        </span>
                      </h3>
                      <h3 v-else class="txt">{{ message.title }}</h3>
                      <div class="txt_wrap">
                        <div class="board_time">
                          <img
                            src="@/assets/front_component/images/assets/board01.png"
                            alt="時間"
                          />
                          <time
                            class="en"
                            :datetime="formatDate_time(message.created_at)"
                            >{{ formatDate(message.created_at) }}</time
                          >
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
                <div class="p_t10" v-else-if="post_messages.length == 0">
                  <p>投稿はありません。</p>
                </div>
                <div id="post_load" style="display: none">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
                <div id="post_more_btn" class="t_c" style="display: none">
                  <div class="btn_wide">
                    <button v-on:click="messagePostmore(post_messages_change, $User.id)">
                      さらに読み込む
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="board_wrap" id="target_area">
              <div class="ttl_select_wrap">
                <h3 class="ttl_06" id="thanks-heading">宛先<small>（表彰・サンクス/ホメロン）</small></h3>
                <div class="form_outwrap">
                  <div class="wrap">
                    <div class="in_wrap">
                      <div class="select_wrap">
                        <select
                          v-on:change="onReceiveChange"
                          id="departmentSelectReceive"
                        >
                          <option value="all">すべての宛先</option>
                          <option value="award">表彰</option>
                          <option value="thanks">サンクス/ホメロン</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="news_list_wrap">
                <ul class="news_list board label" v-if="receive_messages.length > 0">
                  <li v-for="message in receive_messages" :key="message.id">
                    <a
                      @click="setReadAt(message)"
                      v-if="message.read_at == null"
                      class="notifications"
                    >
                      <div class="icon_wrap">
                        <img
                          :src="message.iconPath"
                          :alt="message.typejp"
                          class="mouse_out"
                        />
                        <img
                          :src="message.iconPath02"
                          :alt="message.typejp"
                          class="mouse_over"
                        />
                      </div>
                      <div class="cat_name" :class="message.type">
                        {{ message.typejp }}
                      </div>

                      <h3 v-if="message.typejp == 'サンクス'" class="txt">
                        <!-- <img
                          v-if="message?.loading"
                          src="@/assets/images/spinner/spinner.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        /> -->
                        <span v-if="message?.from_user">
                        {{ message.from_user.deleted_at ? message.from_user.last_name + ' (削除済み) ' : message.from_user.last_name }}さんからのサンクスカード
                        </span>
                        <span v-else>--</span>
                      </h3>
                      <h3 v-else class="txt">{{ message.title }}</h3>
                      <div class="txt_wrap">
                        <div class="board_time">
                          <img
                            src="@/assets/front_component/images/assets/board01.png"
                            alt="時間"
                          />
                          <time
                            class="en"
                            :datetime="formatDate_time(message.created_at)"
                            >{{ formatDate(message.created_at) }}</time
                          >
                        </div>
                      </div>
                    </a>

                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                      v-else
                    >
                      <div class="icon_wrap">
                        <img
                          :src="message.iconPath"
                          :alt="message.typejp"
                          class="mouse_out"
                        />
                        <img
                          :src="message.iconPath02"
                          :alt="message.typejp"
                          class="mouse_over"
                        />
                      </div>
                      <div class="cat_name" :class="message.type">
                        {{ message.typejp }}
                      </div>
                      <h3
                        v-if="message.typejp == 'サンクス' && message.from_user"
                        class="txt"
                      >
                        {{ message.from_user.deleted_at ? message.from_user.last_name + ' (削除済み) ' : message.from_user.last_name }}さんからのサンクスカード
                      </h3>
                      <h3 v-else class="txt">{{ message.title }}</h3>
                      <div class="txt_wrap">
                        <div class="board_time">
                          <img
                            src="@/assets/front_component/images/assets/board01.png"
                            alt="時間"
                          />
                          <time
                            class="en"
                            :datetime="formatDate_time(message.created_at)"
                            >{{ formatDate(message.created_at) }}</time
                          >
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
                <div class="p_t10" v-else-if="receive_messages.length == 0">
                  <p>投稿はありません。</p>
                </div>
                <div id="receive_load" style="display: none">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
                <div id="receive_more_btn" class="t_c" style="display: none">
                  <div class="btn_wide">
                    <button
                      v-on:click="
                        messageReceivemore(receive_messages_change, $User.id)
                      "
                    >
                      さらに読み込む
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="board_wrap">
              <h3 class="ttl_06 m_b30">フォトログ</h3>
              <div class="photo_wrap">
                <ul id="gallery_list" v-if="photo_messages.length > 0">
                  <li
                    class="gallery_wrap"
                    v-for="(message, index) in photo_messages"
                    :key="message.id"
                  >
                    <a class="pop-modal gallery_show" :href="'#inline-wrap0' + index">
                      <div
                        class="img_wrap"
                        v-bind:style="{
                          backgroundImage: 'url(' + message.imgpath + ')',
                        }"
                      >
                        <div class="hover_text">
                          <div class="txt_wrap">
                            <h3 class="gallery_ttl">{{ message.content }}</h3>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div
                      :id="'inline-wrap0' + index"
                      class="mfp-hide inline-wrap"
                      data-group="1"
                    >
                      <div class="img_list">
                        <div class="img_wrap">
                          <img :src="message.imgpath" />
                        </div>
                      </div>
                      <div class="txt_wrap photo">
                        <p v-if="message.from_user" class="name">
                          <span v-html="message.from_user.group.name"></span>
                          <span v-html="message.from_user.last_name"></span
                          ><span v-html="message.from_user.first_name"></span>
                        </p>
                        <h3>{{ message.content }}</h3>
                      </div>
                      <button title="Close (Esc)" type="button" class="mfp-close">
                        ×
                      </button>
                    </div>
                  </li>
                </ul>
                <div v-else-if="photo_messages.length == 0">
                  <p>投稿されたフォトログはありません。</p>
                </div>
                <div v-else class="p_t50">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
              </div>
              <div id="photo_load" style="display: none">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
              <div
                id="photo_more_btn"
                class="t_c m_t20"
                v-if="18 < photo_total && now_post_num < photo_total"
              >
                <div class="btn_wide">
                  <button v-on:click="photo_messageListmore">さらに読み込む</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/kusa02.png" alt="草" />
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap04">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/kusa05.png" alt="草" />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap09">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap10">
            <img src="@/assets/front_component/images/assets/kusa07.png" alt="草" />
          </div>
          <div class="deco_wrap11">
            <img src="@/assets/front_component/images/assets/kusa06.png" alt="草" />
          </div>
          <div class="deco_wrap12">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap13">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap14">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap15">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap16">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap17">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球" />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球" />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球" />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー" />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import dayjs from "dayjs";
import "@/assets/front_component/js/functions.js";
import $ from "jquery";
import message from "@/apis/Message";
import User from "@/apis/User";
import Notification from "@/apis/Notification";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import striptags from "striptags";
import Groups from "@/apis/Groups";
import "@/assets/front_component/js/jquery.magnific-popup.min.js";

export default {
  name: "message",
  data() {
    return {
      hash: this.$route.hash,
      editUser: {
        title: "ユーザーの紹介文",
        content: "",
        content_type: "HTML",
        status: "公開",
        type: "user_introduction",
      },
      registration_status: false,
      profileImg: false,

      awardOneNum: "",
      awardTenNum: "",
      thanksNum: "",
      thanksAllNum: "",
      thanksCardSplit: "",

      thanksNumMouth: 0,
      thanksIdsCount: 0,
      columnNumMouth: 0,
      ideaNumMouth: 0,
      dailyNumMouth: 0,

      receive_total: "",
      receive_messages: [],
      receive_messages_change: "award,thanks",
      receive_more_load: false,
      now_receive_num: 5, // 表示されている数
      get_receive_num: 20, // 取得する数

      post_total: "",
      post_messages: [],
      post_messages_change: "news,thanks,column,idea,daily",
      post_more_load: false,
      now_post_num: 5, // 表示されている数
      get_post_num: 20, // 取得する数

      photo_messages: [],
      photo_images: [],
      photo_now_post_num: 18, // 表示されている数
      photo_get_post_num: 18, // 取得する数
      photo_more_load: false,
      photo_total: "",

      sdgs_show: {},
      sdgs_show_id: "",
      sdgs_show_status: "",
      challenge_show: {},
      challenge_show_id: "",

      termYear: "",
      sdgsList: [],

      approvalNum: "",
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  watch: {
    all_messages(val) {
      this.all_messages = val;
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      console.log(location.hash);
      if (location.hash !== "") {
        $(window).scrollTop(0);
      }
    });
  },
  created() {
    this.termyear();
    // this.thanksCountMonth("thanks", this.$User.id);
    // this.columnCountMonth("column", this.$User.id);
    // this.ideaCountMonth("idea", this.$User.id);
    // this.dailyCountMonth("daily", this.$User.id);

    this.postsCountMonth(this.$User.id); //user post messages monthly count
    this.messageReceivedCount(this.$User.id); //thanks and award counts

    this.getIntroduction("user_introduction");
    // this.getIntroduction(this.$User.id);
    this.profileImgApi();
    // this.awardCount("award", this.$User.id);
    // this.thanksCount("thanks", this.$User.id);
    this.photo_messageList();
    this.messagePost("news,thanks,column,idea,daily", 5, this.$User.id);
    this.messageReceive("award,thanks", this.$User.id, 5);
    this.approvalCount(this.$User.id);
  },
  mounted() {
    document.body.className = "page_control";

    this.$nextTick(function () {
      if (this.hash) {
        this.scrollToAnchorPoint(this.hash);
      }
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        if (location.hash !== "") {
          $(window).scrollTop(0);
        }
      });
      $(function () {
        var ua = navigator.userAgent;
        $(document).on(
          "click",
          'a[href^="#"]:not(".pop-modal"):not(".news-modal")',
          function () {
            var speed = 550;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? "html" : href);

            if (
              ua.indexOf("iPhone") > 0 ||
              (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
            ) {
              var position = target.offset().top - 80; //ヘッダの高さ分位置をずらす
            } else {
              position = target.offset().top - 110; //ヘッダの高さ分位置をずらす
            }

            $("html, body").animate({ scrollTop: position }, speed, "swing");
            return false;
          }
        );
        if (
          ua.indexOf("iPhone") > 0 ||
          (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
        ) {
          let headerHeight = 80;
          let urlHash = location.hash;
          if (urlHash) {
            console.log(urlHash);
            let position = $(urlHash).offset().top - headerHeight;
            console.log(position);
            $("html, body").animate({ scrollTop: position }, 0);
          }
        } else {
          let headerHeight = 110;
          let urlHash = location.hash;
          if (urlHash) {
            console.log(urlHash);
            let position = $(urlHash).offset().top - headerHeight;
            console.log(position);
            $("html, body").animate({ scrollTop: position }, 0);
          }
        }
      });
    });
  },
  methods: {
    termyear() {
      // 本日を作成.
      // ローカル現在時刻の取得
      const term_nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)
      const term_nowLocalMonth = term_nowLocal.getMonth() + 1;
      const term_beginningYear = term_nowLocal;
      if (term_nowLocalMonth > 9) {
        console.log("来年");
        term_beginningYear.setYear(term_nowLocal.getFullYear() + 1);
        //console.log(term_nowLocal.getFullYear() + 1);
      } else {
        console.log("今年");
        //console.log(term_nowLocal.getFullYear());
      }

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const diff = term_beginningYear.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const plusLocal = new Date(term_beginningYear - diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let iso = plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      iso = iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      ////console.log(iso);
      var target_term = iso.slice(0, 4);
      //console.log(target_term);
      this.termYear = target_term;
      console.log('term', this.termYear);

      this.sdgsTerm("sdgs", 30, this.$User.id);
      this.approvelSdgsAndChallenge("sdgs", 30, this.$User.id);
      this.challengeTerm("challenge", 30, this.$User.id);
    },
    sdgsTerm(type, num, user) {
      this.loader = true;
      message
        .myPostMessages(type, num, user)
        .then((response) => {
          if (response != null) {
            var sdgsTermList = response.data.data;
            console.log('data',response.data.data);

            let date = new Date();

            let nowYear = date.getFullYear();

            if (date.getMonth() > 9) {
                nowYear = nowYear + 1;
            }

            for (let i = 0; i < sdgsTermList.length; i++) {
              // console.log(i);
              var contentSplit = JSON.parse(sdgsTermList[i].content);

              if (contentSplit["option"] == nowYear) {
                var sdgsTermoOption = contentSplit;
                console.log(sdgsTermoOption["option"]);
                // if (sdgsTermoOption["option"] == this.termYear) {
                  //   }
                this.sdgs_show = sdgsTermoOption;
                this.sdgs_show_id = sdgsTermList[i].id;
                this.sdgs_show_status = sdgsTermList[i].status;
  
                this.sdgsCount(this.sdgs_show_id);
                break;
              }
            }

            this.sdgs_show["content"] = striptags(this.sdgs_show["content"]);
            this.sdgs_show["content"] = this.sdgs_show["content"].replace(/&nbsp;/g, "");

            this.sdgs_show["content_1"] = striptags(this.sdgs_show["content_1"]);
            this.sdgs_show["content_1"] = this.sdgs_show["content_1"].replace(
              /&nbsp;/g,
              ""
            );

            this.sdgs_show["content_2"] = striptags(this.sdgs_show["content_2"]);
            this.sdgs_show["content_2"] = this.sdgs_show["content_2"].replace(
              /&nbsp;/g,
              ""
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    sdgsCount(msgId) {
      this.loader = true;
      message
        .messageCount(msgId)
        .then((response) => {
          console.log('res',response.data);
          this.sdgs_show["goal_count_1"] = response.data.goal_count_1
          this.sdgs_show["goal_count_2"] = response.data.goal_count_2
          this.sdgs_show["goal_count_others"] = response.data.goal_count_others
          this.sdgs_show["total_goal_count"] = response.data.total_goal_count
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    challengeTerm(type, num, user) {
      this.loader = true;
      message
        .myPostMessages(type, num, user)
        .then((response) => {
          if (response != null) {
            var challengeTermList = response.data.data;
            console.log(challengeTermList);
            for (let i = 0; i < challengeTermList.length; i++) {
              var contentSplit = JSON.parse(challengeTermList[i].content);
              var challengeTermoOption = contentSplit;
              //console.log(challengeTermoOption["option"]);
              // if (challengeTermoOption["option"] == this.termYear) {
              this.challenge_show = challengeTermoOption;
              this.challenge_show_id = challengeTermList[i].id;
              break;
              // }
            }

            this.challenge_show["content"] = striptags(this.challenge_show["content"]);
            this.challenge_show["content"] = this.challenge_show["content"].replace(
              /&nbsp;/g,
              ""
            );

            this.challenge_show["content_1"] = striptags(
              this.challenge_show["content_1"]
            );
            this.challenge_show["content_1"] = this.challenge_show["content_1"].replace(
              /&nbsp;/g,
              ""
            );

            this.challenge_show["content_2"] = striptags(
              this.challenge_show["content_2"]
            );
            this.challenge_show["content_2"] = this.challenge_show["content_2"].replace(
              /&nbsp;/g,
              ""
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    approvalCount(user) {
      message
        .listNumCountApproval(user)
        .then((response) => {
          if (response != null) {
            this.approvalNum = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    onPostChange: function (e) {
      this.type_name = e.target.value;
      if (this.type_name == "all") {
        this.messagePost("news,thanks,column,idea,daily", 5, this.$User.id);
        this.post_messages_change = "news,thanks,column,idea,daily";
      } else {
        this.messagePost(this.type_name, 5, this.$User.id);
        this.post_messages_change = this.type_name;
      }
    },
    onReceiveChange: function (e) {
      this.type_name = e.target.value;
      if (this.type_name == "all") {
        this.messageReceive("award,thanks", this.$User.id, 5);
        this.receive_messages_change = "award,thanks";
      } else {
        this.messageReceive(this.type_name, this.$User.id, 5);
        this.receive_messages_change = this.type_name;
      }
    },
    messagePost(type, num, user) {
      this.loader = true;
      message
        .myPostMessages(type, num, user)
        .then((response) => {
          if (response != null) {
            this.post_total = response.data.total;
            this.post_messages = response.data.data;
            if (5 < this.post_total) {
              $("#post_more_btn").show();
            } else {
              $("#post_more_btn").hide();
            }
            for (let key in this.post_messages) {
              if (this.post_messages[key].type == "news") {
                this.post_messages[key].typejp = "お知らせ";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab09_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab09_over.png");
              } else if (this.post_messages[key].type == "thanks") {
                this.post_messages[key].typejp = "サンクス";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab04_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab04_over.png");
                if (this.post_messages[key].target_ids != null) {
                  this.post_messages[key].target_user = [];
                  this.post_messages[key].target_ids.forEach((user_id) => {
                    User.show(user_id)
                      .then((response) => {
                        if (response != null) {
                          this.post_messages[key].target_user.push(response.data.data);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {});
                  });
                }
              } else if (this.post_messages[key].type == "column") {
                this.post_messages[key].typejp = "コラム";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab07_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab07_over.png");
              } else if (this.post_messages[key].type == "idea") {
                this.post_messages[key].typejp = "アイディア";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab06_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab06_over.png");
              } else if (this.post_messages[key].type == "daily") {
                this.post_messages[key].typejp = "気づき日報";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab10_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab10_over.png");
              } else {
                this.post_messages[key].typejp = "その他";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab01_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab01_over.png");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messagePostmore(type, user) {
      $("#post_load").show();
      $("#post_more_btn").hide();

      this.now_post_num = this.now_post_num + this.get_post_num;

      message
        .myPostMessages(type, this.now_post_num, user)
        .then((response) => {
          this.post_more_load = true;
          if (response != null) {
            this.post_total = response.data.total;
            this.post_messages = response.data.data;
            if (this.now_post_num < this.post_total) {
              $("#post_more_btn").show();
            } else {
              $("#post_more_btn").hide();
            }
            for (let key in this.post_messages) {
              if (this.post_messages[key].type == "news") {
                this.post_messages[key].typejp = "お知らせ";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab09_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab09_over.png");
              } else if (this.post_messages[key].type == "thanks") {
                this.post_messages[key].typejp = "サンクス";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab04_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab04_over.png");
                if (this.post_messages[key].target_ids != null) {
                  this.post_messages[key].target_user = [];
                  this.post_messages[key].target_ids.forEach((user_id) => {
                    User.show(user_id)
                      .then((response) => {
                        if (response != null) {
                          this.post_messages[key].target_user.push(response.data.data);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {});
                  });
                }
              } else if (this.post_messages[key].type == "column") {
                this.post_messages[key].typejp = "コラム";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab07_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab07_over.png");
              } else if (this.post_messages[key].type == "idea") {
                this.post_messages[key].typejp = "アイディア";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab06_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab06_over.png");
              } else if (this.post_messages[key].type == "daily") {
                this.post_messages[key].typejp = "気づき日報";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab10_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab10_over.png");
              } else {
                this.post_messages[key].typejp = "その他";
                this.post_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab01_out.png");
                this.post_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab01_over.png");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageReceive(types, user, num) {
      this.loader = true;
      message
        .myTargetMessages(types, user, num)
        .then((response) => {
          if (response != null) {
            this.receive_total = response.data.total;
            this.receive_messages = response.data.data;
            if (5 < this.receive_total) {
              $("#receive_more_btn").show();
            } else {
              $("#receive_more_btn").hide();
            }
            for (let key in this.receive_messages) {
              if (this.receive_messages[key].type == "award") {
                this.receive_messages[key].typejp = "表彰";
                this.receive_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab03_out.png");
                this.receive_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab03_over.png");
              } else if (this.receive_messages[key].type == "thanks") {
                this.receive_messages[key].typejp = "サンクス";
                this.receive_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab04_out.png");
                this.receive_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab04_over.png");
                if (this.receive_messages[key].from_id != null) {
                  User.show(this.receive_messages[key].from_id)
                    .then((response) => {
                      if (response != null) {
                        this.receive_messages[key].from_user = response.data.data;
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
              } else {
                this.receive_messages[key].typejp = "その他";
                this.receive_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab01_out.png");
                this.receive_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab01_over.png");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageReceivemore(types, user) {
      $("#receive_load").show();
      $("#receive_more_btn").hide();

      this.now_receive_num = this.now_receive_num + this.get_receive_num;

      message
        .myTargetMessages(types, user, this.now_receive_num)
        .then((response) => {
          this.receive_more_load = true;
          if (response != null) {
            this.receive_total = response.data.total;

            this.receive_messages = response.data.data;
            if (this.now_receive_num < this.receive_total) {
              $("#receive_more_btn").show();
            } else {
              $("#receive_more_btn").hide();
            }
            for (let key in this.receive_messages) {
              if (this.receive_messages[key].type == "award") {
                this.receive_messages[key].typejp = "表彰";
                this.receive_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab03_out.png");
                this.receive_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab03_over.png");
              } else if (this.receive_messages[key].type == "thanks") {
                this.receive_messages[key].typejp = "サンクス";
                this.receive_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab04_out.png");
                this.receive_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab04_over.png");
                if (this.receive_messages[key].from_id != null) {
                  User.show(this.receive_messages[key].from_id)
                    .then((response) => {
                      if (response != null) {
                        this.receive_messages[key].from_user = response.data.data;
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
              } else {
                this.receive_messages[key].typejp = "その他";
                this.receive_messages[
                  key
                ].iconPath = require("@/assets/front_component/images/assets/tab01_out.png");
                this.receive_messages[
                  key
                ].iconPath02 = require("@/assets/front_component/images/assets/tab01_over.png");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    formatHireDate: (dateStr) => dayjs(dateStr).format("YYYY年MM月DD日"),
    formatPublishedDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
      }
    },
    register() {
      $("#js-loader").fadeIn();
      this.loader = true;
      message
        .register(this.editUser)
        .then(
          (response) => {
            window.location.reload();
            $("#js-loader").fadeOut();
          },
          (error) => {
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    update(id) {
      this.loader = true;
      message
        .update(this.editUser, id)
        .then(
          (response) => {
            window.location.reload();
          },
          (error) => {
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    awardCount(type, user) {
      this.loader = true;
      message
        .listNumCount(type, user)
        .then((response) => {
          if (response != null) {
            var str = String(response.data.data.length);
            str = String(20);
            if (str.length > 1) {
              var footSliceOne = str.slice(-1);
              var numOne = Number(footSliceOne);
              this.awardOneNum = numOne;

              var footSliceTen = str.slice(0, -1);
              var numTen = Number(footSliceTen);
              this.awardTenNum = numTen;
            } else {
              this.awardOneNum = response.data.data.length;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    thanksCount(type, user) {
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)
      const nowLocalMonth = nowLocal.getMonth() + 1;
      const beginningYear = nowLocal;
      beginningYear.setMonth(9);
      beginningYear.setDate(1);
      beginningYear.setHours(0);
      beginningYear.setMinutes(0);
      beginningYear.setSeconds(0);
      if (nowLocalMonth > 9) {
        console.log("nowLocalMonth");
      } else {
        beginningYear.setYear(nowLocal.getFullYear() - 1);
      }

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const diff = beginningYear.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const plusLocal = new Date(beginningYear - diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let iso = plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      iso = iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(iso);

      //通算
      message
        .listNumCount(type, user)
        .then((response) => {
          if (response != null) {
            this.thanksAllNum = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });

      //年度
      message
        .listNumCount(type, user, iso)
        .then((response) => {
          if (response != null) {
            this.thanksNum = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageReceivedCount(user){
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)
      const nowLocalMonth = nowLocal.getMonth() + 1;
      const beginningYear = nowLocal;
      beginningYear.setMonth(9);
      beginningYear.setDate(1);
      beginningYear.setHours(0);
      beginningYear.setMinutes(0);
      beginningYear.setSeconds(0);
      if (nowLocalMonth > 9) {
       // console.log("nowLocalMonth");
      } else {
        beginningYear.setYear(nowLocal.getFullYear() - 1);
      }

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const diff = beginningYear.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const plusLocal = new Date(beginningYear - diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let iso = plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      iso = iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(iso);

      message
        .targetMessageCount(user, iso)
        .then((response) => {
          if (response != null) {
            //thanks count
            this.thanksAllNum = response.data.thanks_all_count;
            this.thanksNum = response.data.thanks_count;

            //award count
            var str = String(response.data.award_count);
            if (str.length > 1) {
              var footSliceOne = str.slice(-1);
              var numOne = Number(footSliceOne);
              this.awardOneNum = numOne;

              var footSliceTen = str.slice(0, -1);
              var numTen = Number(footSliceTen);
              this.awardTenNum = numTen;
            } else {
              this.awardOneNum = response.data.award_count;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    postsCountMonth(user){
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)

      const start_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      start_nowLocalMonth.setDate(1);
      start_nowLocalMonth.setHours(0);
      start_nowLocalMonth.setMinutes(0);
      start_nowLocalMonth.setSeconds(0);
      //console.log(start_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const start_diff = start_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const start_plusLocal = new Date(start_nowLocalMonth - start_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let start_iso = start_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      start_iso = start_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(start_iso);

      const end_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      end_nowLocalMonth.setHours(23);
      end_nowLocalMonth.setMinutes(59);
      end_nowLocalMonth.setSeconds(59);
      //console.log(end_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const end_diff = end_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const end_plusLocal = new Date(end_nowLocalMonth - end_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let end_iso = end_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      end_iso = end_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(end_iso);
      message
        .myPostCount(user, start_iso, end_iso)
        .then((response) => {
          if (response != null) {
            let countObj = response.data;
            this.columnNumMouth = countObj.column_count;
            this.thanksNumMouth = countObj.thanks_count;
            this.thanksIdsCount = countObj.target_ids_count;
            this.ideaNumMouth = countObj.idea_count;
            this.dailyNumMouth = countObj.daily_count;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    thanksCountMonth(type, user) {
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)

      const start_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      start_nowLocalMonth.setDate(1);
      start_nowLocalMonth.setHours(0);
      start_nowLocalMonth.setMinutes(0);
      start_nowLocalMonth.setSeconds(0);
      //console.log(start_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const start_diff = start_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const start_plusLocal = new Date(start_nowLocalMonth - start_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let start_iso = start_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      start_iso = start_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(start_iso);

      const end_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      end_nowLocalMonth.setHours(23);
      end_nowLocalMonth.setMinutes(59);
      end_nowLocalMonth.setSeconds(59);
      //console.log(end_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const end_diff = end_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const end_plusLocal = new Date(end_nowLocalMonth - end_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let end_iso = end_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      end_iso = end_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(end_iso);

      message
        .listNumCountMonth(type, user, start_iso, end_iso)
        .then((response) => {
          if (response != null) {
            var thanks_count = null;
            if (response.data.data.length >= 1) {
              response.data.data.forEach((element) => {
                thanks_count += element.target_ids.length;
              });
            } else {
              thanks_count = response.data.data.length;
            }
            this.thanksNumMouth = thanks_count;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    columnCountMonth(type, user) {
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)

      const start_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      start_nowLocalMonth.setDate(1);
      start_nowLocalMonth.setHours(0);
      start_nowLocalMonth.setMinutes(0);
      start_nowLocalMonth.setSeconds(0);
      //console.log(start_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const start_diff = start_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const start_plusLocal = new Date(start_nowLocalMonth - start_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let start_iso = start_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      start_iso = start_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(start_iso);

      const end_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      end_nowLocalMonth.setHours(23);
      end_nowLocalMonth.setMinutes(59);
      end_nowLocalMonth.setSeconds(59);
      //console.log(end_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const end_diff = end_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const end_plusLocal = new Date(end_nowLocalMonth - end_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let end_iso = end_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      end_iso = end_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(end_iso);
      
      message
        .myPostCount(type, user, start_iso, end_iso)
        .then((response) => {
          if (response != null) {
            this.columnNumMouth = response.data.column_count;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    ideaCountMonth(type, user) {
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)

      const start_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      start_nowLocalMonth.setDate(1);
      start_nowLocalMonth.setHours(0);
      start_nowLocalMonth.setMinutes(0);
      start_nowLocalMonth.setSeconds(0);
      //console.log(start_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const start_diff = start_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const start_plusLocal = new Date(start_nowLocalMonth - start_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let start_iso = start_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      start_iso = start_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(start_iso);

      const end_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      end_nowLocalMonth.setHours(23);
      end_nowLocalMonth.setMinutes(59);
      end_nowLocalMonth.setSeconds(59);
      //console.log(end_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const end_diff = end_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const end_plusLocal = new Date(end_nowLocalMonth - end_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let end_iso = end_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      end_iso = end_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(end_iso);

      message
        .listNumCountMonth(type, user, start_iso, end_iso)
        .then((response) => {
          if (response != null) {
            this.ideaNumMouth = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    dailyCountMonth(type, user) {
      this.loader = true;

      // 本日を作成.
      // ローカル現在時刻の取得
      const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)

      const start_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      start_nowLocalMonth.setDate(1);
      start_nowLocalMonth.setHours(0);
      start_nowLocalMonth.setMinutes(0);
      start_nowLocalMonth.setSeconds(0);
      //console.log(start_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const start_diff = start_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const start_plusLocal = new Date(start_nowLocalMonth - start_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let start_iso = start_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      start_iso = start_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(start_iso);

      const end_nowLocalMonth = new Date(
        nowLocal.getFullYear(),
        nowLocal.getMonth() + 1,
        0
      );
      end_nowLocalMonth.setHours(23);
      end_nowLocalMonth.setMinutes(59);
      end_nowLocalMonth.setSeconds(59);
      //console.log(end_nowLocalMonth);

      // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
      const end_diff = end_nowLocalMonth.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
      // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
      const end_plusLocal = new Date(end_nowLocalMonth - end_diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
      // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
      let end_iso = end_plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      end_iso = end_iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"
      //console.log(end_iso);

      message
        .listNumCountMonth(type, user, start_iso, end_iso)
        .then((response) => {
          if (response != null) {
            console.log(response.data.data.length);
            this.dailyNumMouth = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getIntroduction(type) {
      this.loader = true;
      message
        .list(type)
        // .userIntroduction(user)
        .then(
          (response) => {
           if (response != null) {
            this.messages = response.data.data;
       
              this.messages.forEach((ob, i) => {
          
                if(ob.act_users!=null || ob.act_users!=undefined)
                {
                
                  if (this.$User.id == ob.act_users[0].user_id) 
                  {
                    this.editUser = ob;
                    this.registration_status = ob.id;
                    this.editUser.published_at = this.formatPublishedDate(this.editUser.published_at);
                  }
                }
               
              });
              // this.editUser = response.data;
              // this.registration_status = response.data.id;
              // this.editUser.published_at = this.formatPublishedDate(this.editUser.published_at);
            }
          },
          (error) => {
            console.log(error);

            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    scrollToAnchorPoint(refName) {
      //スクロールのスピード
      var speed = 500;
      //リンク先を取得
      var target = $(refName);
      //リンク先までの距離を取得
      var position = target.offset().top - 150;
      //スムーススクロール
      $("html, body").animate({ scrollTop: position }, speed, "swing");
      return false;
    },
    statusChange: function (event) {
      if (event.target.value != "") {
        this.all_messages = this.temp_All_messages.filter((item) => {
          return item.type == event.target.value;
        });
      } else {
        this.all_messages = this.temp_All_messages;
      }
    },
    setReadAt(message) {
      Notification.getNotificationByMessageId(message.id)
        .then(
          (response) => {
            if (response != null) {
              Notification.notificationRead(response.data.data.id).then((response) => {
                if (response != null) {
                  this.$router.push({
                    name: "Front show",
                    params: { type: message.type, id: message.id },
                  });
                }
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    photo_messageList() {
      this.loader = true;
      message
        .myPostMessages("photolog", this.photo_now_post_num, this.$User.id)
        .then(
          (response) => {
            if (response != null) {
              this.photo_messages = response.data.data;
              this.photo_total = response.data.total;
              this.photo_messages.forEach((message_single, i) => {
                message_single.content = striptags(message_single.content);
                message_single.content = message_single.content.replace(/&nbsp;/g, "");
                this.getImage(message_single.id, i);

                User.show(message_single.from_id)
                  .then((response) => {
                    if (response != null) {
                      if (response.data.data.group.parent_id) {
                        Groups.show(response.data.data.group.parent_id)
                          .then((responseGroup) => {
                            if (responseGroup != null) {
                              response.data.data.group.name =
                                responseGroup.data.data.name;
                              message_single.from_user = response.data.data;
                            }
                          })
                          .catch((error) => {
                            message_single.from_user = response.data.data;
                            //console.log(error);
                          })
                          .finally(() => {});
                      } else {
                        message_single.from_user = response.data.data;
                      }
                    }
                  })
                  .catch((error) => {
                    //console.log(error);
                  })
                  .finally(() => {});
              });
              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: true,
                  },
                });
              });
            }
          },
          (error) => {
            console.log(error);
            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    photo_messageListmore() {
      $("#photo_load").show();
      $("#photo_more_btn").hide();

      this.photo_now_post_num = this.photo_now_post_num + this.photo_get_post_num;

      message
        .myPostMessages("photolog", this.photo_now_post_num, this.$User.id)
        .then(
          (response) => {
            this.photo_more_load = true;
            if (response != null) {
              this.photo_messages = response.data.data;
              this.photo_messages.forEach((message_single, i) => {
                message_single.content = striptags(message_single.content);
                message_single.content = message_single.content.replace(/&nbsp;/g, "");
                this.getImage(message_single.id);

                User.show(message_single.from_id)
                  .then((response) => {
                    if (response != null) {
                      if (response.data.data.group.parent_id) {
                        Groups.show(response.data.data.group.parent_id)
                          .then((responseGroup) => {
                            if (responseGroup != null) {
                              //console.log(responseGroup.data.data.name);
                              response.data.data.group.name =
                                responseGroup.data.data.name;
                              message_single.from_user = response.data.data;
                            }
                          })
                          .catch((error) => {
                            message_single.from_user = response.data.data;
                            //console.log(error);
                          })
                          .finally(() => {});
                      } else {
                        message_single.from_user = response.data.data;
                      }
                    }
                  })
                  .catch((error) => {
                    //console.log(error);
                  })
                  .finally(() => {});
              });
              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: true,
                  },
                });
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getImage(id, i) {
      message
        .showfile(id, "key", "photolog")
        .then(
          (response) => {
            if (response != "") {
              this.images = response.data.data;

              let path = this.images[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.photo_messages[i].imgpath = src;
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => {
            console.log(error);
            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch(() => {
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
    yearPeriod() {
      let date = new Date();

      let currentMonth = date.getMonth() + 1;

      if (currentMonth > 9) {
        return date.getFullYear();
      }

      return date.getFullYear() - 1;
    },
    approvelSdgsAndChallenge(type, num, user) {
      this.loader = true;

      message
        .approvelSdgsAndChallenge(type, num, user, this.yearPeriod())
        .then((response) => {
          if (response != null) {
            this.sdgsList = response.data.data.filter((sdgs) => {
              var contentSplit = JSON.parse(sdgs.content);
              sdgs.content = striptags(contentSplit.content).replace(/&nbsp;/g, "");
              sdgs.content_1 = striptags(contentSplit.content_1).replace(/&nbsp;/g, "");
              sdgs.content_2 = striptags(contentSplit.content_2).replace(/&nbsp;/g, "");
              sdgs.option = contentSplit.option;
              return sdgs;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  updated() {
    if (this.receive_more_load == true) {
      $("#receive_load").hide();
      this.receive_more_load = false;
    }
    if (this.post_more_load == true) {
      $("#post_load").hide();
      this.post_more_load = false;
    }
    if (this.photo_more_load == true) {
      $("#photo_load").hide();
      $("#photo_more_btn").show();
      this.photo_more_load = false;
    }
    $(function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        let headerHeight = 80;
        let urlHash = location.hash;
        if (urlHash) {
          //console.log(urlHash);
          let position = $(urlHash).offset().top - headerHeight;
          //console.log(position);
          $("html, body").animate({ scrollTop: position }, 0);
        }
      } else {
        let headerHeight = 110;
        let urlHash = location.hash;
        if (urlHash) {
          //console.log(urlHash);
          let position = $(urlHash).offset().top - headerHeight;
          //console.log(position);
          $("html, body").animate({ scrollTop: position }, 0);
        }
      }
    });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
.goal_mb{
  margin-bottom: 1.2rem;
}
.border-bottom-none{
  border-bottom: none !important;
}
</style>
